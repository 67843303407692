import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import WatchlistTable from "~/components/watchlist/WatchlistTable.vue";
import WatchlistAggTable from "~/components/watchlist/WatchlistAggTable.vue";
import industryToId from "~/utils/templates/industry_to_id.json";
import _idToIndustry from "~/utils/templates/id_to_industry.json";
import watchlistColumns from "~/utils/templates/baseWatchlistColumns.json";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useBusinessMode from "~/functions/useBusinessMode";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  components: {
    WatchlistTable: WatchlistTable,
    WatchlistAggTable: WatchlistAggTable
  },
  setup: function setup() {
    var _useNumberFormatters = useNumberFormatters(),
      calculateSimpleMultiple = _useNumberFormatters.calculateSimpleMultiple;
    var _useBusinessMode = useBusinessMode(),
      hidePrice = _useBusinessMode.hidePrice;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      calculateSimpleMultiple: calculateSimpleMultiple,
      hidePrice: hidePrice,
      refCode: refCode,
      dev: dev,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      baseColumns: [{
        text: "",
        value: "fav",
        columntype: "fav",
        width: "20px",
        align: "left",
        sortable: false
      }, {
        text: "Ticker",
        columntype: "ts",
        value: "ts",
        width: "60px",
        align: "center"
      }, {
        text: "Name",
        columntype: "name",
        value: "name",
        align: "center"
      },
      // {
      //   text: "CompReason",
      //   value: "compid",
      //   // width: "60px",
      //   align: "center"
      // },
      // {
      //   text: "industryid",
      //   value: "iid",
      //   // width: "60px",
      //   align: "center"
      // },
      // {
      //   text: "% Change",
      //   value: "changePercent",
      //   // width: "85px",
      //   align: "center",
      // },
      // {
      //   text: "Currency",
      //   value: "quoteCurrency",
      //   width: "40px",
      //   align: "center",
      //   sortable: false
      // },
      // {
      //   text: "Market Time",
      //   value: "priceTime",
      //   width: "125px",
      //   align: "center",
      //   sortable: false
      // },
      {
        text: "NTM TEV/REV",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100180",
        align: "center",
        num: "tevObj",
        denom: "100180"
      }, {
        text: "NTM TEV/EBITDA",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100187",
        align: "center",
        num: "tevObj",
        denom: "100187"
      }, {
        text: "NTM P/E",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100173",
        align: "center",
        num: "cObj",
        denom: "100173"
      }, {
        text: "NTM MC/FCF",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-114221",
        align: "center",
        num: "mcObj",
        denom: "114221"
      }, {
        text: "NTM P/FFO",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100194",
        align: "center",
        num: "cObj",
        denom: "100194"
      }, {
        text: "NTM P/AFFO",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-114155",
        align: "center",
        num: "cObj",
        denom: "114155"
      }, {
        text: "Last Price",
        columntype: "lastPrice",
        value: "lastPrice",
        width: "70px",
        align: "center"
      }, {
        text: "Mean Analyst Target",
        columntype: "simpleStreet",
        value: "val-float-100161",
        align: "center",
        did: "100161"
      }, {
        text: "Mean Analyst NAV",
        columntype: "simpleStreet",
        value: "val-float-114210",
        align: "center",
        did: "114210"
      },
      // {
      //   text: "Volume",
      //   value: "volume",
      //   align: "center",
      //   width: "100px",
      // },
      {
        text: "Market Cap",
        columntype: "marketCap",
        value: "val-float-marketcap",
        align: "center",
        width: "120px"
      }, {
        text: "TEV",
        columntype: "tev",
        value: "val-float-tev",
        align: "center",
        width: "120px"
      } // { text: "YTD %", value: "ytdChange", width: "90px", align: "center" },
      // { text: "P/E Ratio", value: "peRatio", width: "75px", align: "center" },
      // { text: "Actions", value: "action", sortable: false, align: "center" }
      ],
      removeEmptyRows: false,
      activeIndustry: 0,
      addActive: true,
      showSummaryStats: true
    };
  },
  computed: {
    error: function error() {
      return this.$store.state.ciq.competitorsError;
    },
    loading: function loading() {
      return this.$store.state.ciq.fetchingCompetitors;
    },
    data: function data() {
      // so state.ciq.competitors.comp = an array of competitors eh?
      return this.$store.state.watchlist.ciq || {};
    },
    rawComps: function rawComps() {
      return this.$store.state.ciq.competitors || {
        comp: []
      };
    },
    compColumns: function compColumns() {
      var _this = this;
      var activeIndustryIndex = this.activeIndustry;
      var activeIndustry = activeIndustryIndex === 0 ? this.industryId : this.compIndustries[activeIndustryIndex - 1];
      var tidArr = this.addActiveSymbolToArray(this.compsById[activeIndustry]); // const marketCapValues = ["val-float-marketcap", "val-float-tev"]
      var columnsWithData = this.baseColumns.filter(function (c) {
        // const did = c.did || c.denom
        if (c.value && c.value.split("-").length > 1) {
          return tidArr.filter(function (tid) {
            try {
              // FIXME: if you're going to show singular values the second condition
              // needs to be modified
              // not sure if that is the case with this weird ternary statement?
              return Boolean(tid[c.value]);
            } catch (error) {
              return false;
            }
          }).length > 0;
        } else {
          // check for TEV and marketcap?
          return true;
        }
      }).map(function (col) {
        col.text = _this.I18nFn(col.text);
        return col;
      });
      return this.hidePrice ? columnsWithData.filter(function (f) {
        var priceToHide = ["lastPrice", "priceTime", "changePercent", "val-float-100161", "val-float-marketcap", "val-float-tev"];
        return !priceToHide.includes(f.values);
      }) : columnsWithData;
    },
    comp: function comp() {
      var _this2 = this;
      var origComps = this.rawComps.comp || []; /**
                                                * comp objects are:
                                                * {
                                                * bid: "basecompanyid",
                                                * cid: "company Id",
                                                * compid: "competitorSourcetypeId?",
                                                * ecurrid: "exchangeCurrencyId",
                                                * eid: "exchangeId",
                                                * iid: "simple industry id?",
                                                * n: "companyName",
                                                * s: "trading symbol",
                                                * tid: "tradingitemid",
                                                * tstatus: "tradingitemid status",
                                                * }
                                                *
                                                * WatchListTable is Expecting the Objects to Be like
                                                * {
                                                * name: "",
                                                * ts: "",
                                                * tid: "",
                                                * }
                                                */
      var currentTid = this.ticker.tradingitemid;
      var data = this.data;
      var activeData = data[currentTid];
      var comps = origComps.filter(function (f) {
        if (_this2.removeEmptyRows) {
          if (data[f.tid]) {
            return f.tstatus === 15;
          } else {
            return false;
          }
        } else {
          // return true
          return f.tstatus === 15;
        }
      }).map(function (m) {
        var sortObj = {};
        if (data[m.tid]) {
          sortObj = _this2.transformWatchlistForSort(data[m.tid], activeData);
        }
        return _objectSpread(_objectSpread(_objectSpread({}, m), sortObj), {}, {
          name: m.n,
          ts: m.s,
          industryDiff: Math.abs(_this2.industryId - m.iid)
        });
      }); // .sort((a, b) => b.marketCap - a.marketCap)
      // .sort((a, b) => a.mcDiff - b.mcDiff)
      // .sort((a, b) => a.industryDiff - b.industryDiff)
      return comps || [];
    },
    compsById: function compsById() {
      var byId = this.comp.reduce(function (acc, c) {
        if (acc[c.iid]) {
          acc[c.iid].push(c);
        } else {
          acc[c.iid] = [c];
        }
        return acc;
      }, {});
      return byId;
    },
    compIndustries: function compIndustries() {
      var _this3 = this;
      return Object.keys(this.compsById).filter(function (f) {
        return f !== _this3.industryId;
      }).sort(function (a, b) {
        return _this3.compsById[b].length - _this3.compsById[a].length;
      });
    },
    ticker: function ticker() {
      return this.$store.state.ciq.ticker || {};
    },
    activeCompObj: function activeCompObj() {
      var sortObj = {};
      var activeTradingItem = {
        // what are the keys that go here?
        name: this.ticker.companyname,
        ts: this.ticker.tickersymbol,
        tid: this.ticker.tradingitemid,
        cid: this.ticker.companyid
      };
      sortObj = this.transformWatchlistForSort(this.data[activeTradingItem.tid]);
      return _objectSpread(_objectSpread({}, activeTradingItem), sortObj);
    },
    industryId: function industryId() {
      var industry = this.ticker.simpleindustrydescription;
      if (industry) {
        return industryToId[industry];
      } else {
        return 0;
      }
    },
    splitComps: {
      get: function get() {
        return this.$store.state.config.splitComps;
      },
      set: function set(value) {
        this.$store.commit("config/toggleSwitch", {
          type: "splitComps",
          value: value
        });
      }
    },
    usdComps: {
      get: function get() {
        return this.$store.state.config.usdComps;
      },
      set: function set(value) {
        this.$store.commit("config/toggleSwitch", {
          type: "usdComps",
          value: value
        });
      }
    }
  },
  methods: {
    transformWatchlistForSort: function transformWatchlistForSort() {
      var _this4 = this;
      var tickerData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var activeData = arguments.length > 1 ? arguments[1] : undefined; // tickerdata is the object result from transformWatchlistResponse
      var sortObj = {};
      sortObj.lastPrice = this.watchlistPrice(tickerData.cObj);
      sortObj.changePercent = this.percentChange(tickerData);
      sortObj.volume = tickerData.v ? Number(tickerData.v) : undefined;
      watchlistColumns.filter(function (f) {
        return f.value && f.value.split("-")[0] === "div";
      }).forEach(function (header) {
        var _tickerData$denom;
        var value = header.value,
          num = header.num,
          denom = header.denom;
        if ((_tickerData$denom = tickerData[denom]) !== null && _tickerData$denom !== void 0 && _tickerData$denom.unauth) {
          sortObj[value] = "unauth";
        } else {
          sortObj[value] = _this4.calculateSimpleMultiple(tickerData[num], tickerData[denom]);
        }
      }); // sortObj.meanTarget = this.watchlistPrice(tickerData["100161"])
      // sortObj.meanNAV = this.watchlistPrice(tickerData["114210"])
      sortObj["val-float-100161"] = this.watchlistPrice(tickerData["100161"]);
      sortObj["val-float-114210"] = this.watchlistPrice(tickerData["114210"]);
      var compMc = this.watchlistPrice(tickerData.mcObj);
      sortObj.marketCap = compMc;
      sortObj["val-float-marketcap"] = compMc;
      sortObj.tev = this.watchlistPrice(tickerData.tevObj);
      sortObj["val-float-tev"] = this.watchlistPrice(tickerData.tevObj);
      if (activeData) {
        var activeMc = this.watchlistPrice(activeData.mcObj);
        if (activeMc && compMc) {
          var mcDiff = activeMc - compMc; // I want to sort based on the difference, closest to furthest away yeah take the abs
          sortObj.mcDiff = Math.abs(mcDiff);
        }
      }
      return sortObj;
    },
    percentChange: function percentChange(data) {
      // y0 is the value of interest at t0, y1 is the value of interest at t1
      // where t0 < t1 thereform %change = (y1 - y0) / y0
      // Also assuming that y0 and y1 are numbers stored as strings so they need
      // to be parsed in order to do the math
      try {
        var y0 = data.o;
        var y1 = data.c;
        if (y0) {
          var v0 = parseFloat(y0);
          var v1 = parseFloat(y1);
          var delta = v1 - v0;
          var percentChange = delta / v0;
          return percentChange;
        }
      } catch (error) {
        this.transformError(error);
      }
    },
    watchlistPrice: function watchlistPrice() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var valueKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "v";
      var conversionKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "pc";
      try {
        var toUSD = data[conversionKey] || 1;
        var closePrice = data[valueKey];
        if (data.unauth) {
          return "unauth";
        }
        if (closePrice) {
          return closePrice / toUSD;
        }
      } catch (error) {
        this.transformError(error);
      }
    },
    transformError: function transformError(error) {
      if (this.dev) {
        console.error("error transforming compsTable data: ", error);
      }
    },
    idToIndustry: function idToIndustry(iid) {
      return _idToIndustry[iid] || "";
    },
    addActiveSymbolToArray: function addActiveSymbolToArray() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var addActive = this.addActive;
      var activeSymbol = this.activeCompObj;
      var sortedArr = arr.sort(function (a, b) {
        var aMc = a.marketCap || 0;
        var bMc = b.marketCap || 0;
        return bMc - aMc;
      });
      if (addActive) {
        // put it at the beginning of the array
        return [activeSymbol].concat(sortedArr);
      }
      return sortedArr;
    }
  }
});