var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[(_vm.error)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v("\n      "+_vm._s(`An error has occured fetching competitors`)+"\n    ")]):(_vm.loading)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1):(_vm.comp.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-switch',{staticClass:"ma-2",attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Convert to USD'
          )}: ${_vm.$Amplify.I18n.get(_vm.usdComps)}`,"color":"primaryAction"},model:{value:(_vm.usdComps),callback:function ($$v) {_vm.usdComps=$$v},expression:"usdComps"}}),_vm._v(" "),_c('v-switch',{staticClass:"ma-2",attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Show Summary Statistics Below Competitors'
          )}: ${_vm.$Amplify.I18n.get(_vm.showSummaryStats)}`,"color":"primaryAction"},model:{value:(_vm.showSummaryStats),callback:function ($$v) {_vm.showSummaryStats=$$v},expression:"showSummaryStats"}})],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[(!_vm.splitComps)?[_c('v-col',{attrs:{"cols":"12"}},[_c('WatchlistTable',{attrs:{"wltype":"competitors","list":{ tickers: _vm.addActiveSymbolToArray(_vm.comp) },"ciq-data":_vm.data,"active-columns":_vm.compColumns,"usd":_vm.usdComps,"activetid":_vm.ticker.tradingitemid,"dense":true,"disable-pagination":false}})],1)]:[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"color":"primaryAction","centered":"","center-active":"","show-arrows":""},model:{value:(_vm.activeIndustry),callback:function ($$v) {_vm.activeIndustry=$$v},expression:"activeIndustry"}},[_c('v-tab',[_vm._v("\n                "+_vm._s(_vm.I18nFn(_vm.ticker["simpleindustrydescription"]))+"\n              ")]),_vm._v(" "),_vm._l((_vm.compIndustries),function(ind,indIdx){return _c('v-tab',{key:indIdx},[_vm._v("\n                "+_vm._s(_vm.I18nFn(_vm.idToIndustry(ind)))+"\n              ")])})],2)],1),_vm._v(" "),(_vm.activeIndustry === 0)?[_c('v-col',{attrs:{"cols":"12"}},[_c('WatchlistTable',{key:_vm.activeIndustry,attrs:{"wltype":"competitors","list":{
                  tickers: _vm.addActiveSymbolToArray(_vm.compsById[_vm.industryId]),
                },"ciq-data":_vm.data,"active-columns":_vm.compColumns,"usd":_vm.usdComps,"activetid":_vm.ticker.tradingitemid,"dense":true,"disable-pagination":false}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[(_vm.showSummaryStats)?_c('WatchlistAggTable',{attrs:{"list":{ tickers: _vm.compsById[_vm.industryId] },"ciq-data":_vm.data,"active-comp-obj":_vm.activeCompObj,"dense":true}}):_vm._e()],1)]:_vm._e(),_vm._v(" "),_vm._l((_vm.compIndustries),function(iid,cidx){return [(_vm.activeIndustry === cidx + 1)?_c('v-col',{key:iid,attrs:{"cols":"12"}},[_c('WatchlistTable',{key:_vm.activeIndustry,attrs:{"wltype":"competitors","list":{ tickers: _vm.addActiveSymbolToArray(_vm.compsById[iid]) },"ciq-data":_vm.data,"active-columns":_vm.compColumns,"usd":_vm.usdComps,"activetid":_vm.ticker.tradingitemid,"dense":true,"disable-pagination":false}})],1):_vm._e(),_vm._v(" "),_c('v-col',{key:`${iid}agg`,attrs:{"cols":"12"}},[(_vm.activeIndustry === cidx + 1)?_c('WatchlistAggTable',{attrs:{"list":{ tickers: _vm.compsById[iid] },"ciq-data":_vm.data,"active-comp-obj":_vm.activeCompObj,"dense":true}}):_vm._e()],1)]})]],2)],1):_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',[_vm._v("\n        We're sorry, at this time we do not have any competitors for this\n        company.\n      ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }